<script setup>
import { ref, onMounted, computed } from "vue";
import { useRouter } from "vue-router";
import {
  IonIcon,
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
} from "@ionic/vue";
import { starOutline, star } from "ionicons/icons";

import { useGeneralStore } from "../../stores/useGeneralStore";
import ApiService from "../../common/service.api";

const generalStore = useGeneralStore();

const loading = ref({
  gen: false,
});

const router = useRouter();
const currentRisposta = ref(null);
const survey = computed(() => generalStore.currentSurvey);
const selectedRating = ref(null);
const showFinalCard = ref(false);

const HIGHLIGHT_DELAY = 1000; // 1 second delay before moving to the next question

// Lifecycle
onMounted(() => {
  startQuiz();
});

// Methods
function startQuiz() {
  console.log("erogazione", survey.value);

  if (survey.value.risposte.length > 0) {
    currentRisposta.value = survey.value.risposte[0];
  }
}

function getcurrentRisposta() {
  const currentIndex = survey.value.risposte.findIndex(
    (risposta) => risposta.id === currentRisposta.value.id
  );
  if (currentIndex >= 0 && currentIndex < survey.value.risposte.length - 1) {
    currentRisposta.value = survey.value.risposte[currentIndex + 1];
    selectedRating.value = null; // Reset rating for the next question
  } else {
    showFinalCard.value = true; // Show final card to confirm answers
  }
}

function handleRatingClick(e) {
  if (currentRisposta.value.data_risposta || isSurveyCompleteOrDeleted())
    return;
  currentRisposta.value.valore = e;
  // Highlight selected stars before moving to the next question
  setTimeout(() => {
    getcurrentRisposta();
  }, HIGHLIGHT_DELAY);
}

function isSurveyCompleteOrDeleted() {
  return survey.value.completato || survey.value.eliminato;
}

async function confirmCompletion(isConfirmed) {
  if (isConfirmed) {
    survey.value.completato = new Date().toISOString();
    await handleSaveRisposte();

    router.push({ name: "Surveys" });
  } else {
    // Restart quiz from the beginning
    startQuiz();
    showFinalCard.value = false;
  }
}

async function handleSaveRisposte() {
  loading.value.gen = true;
  try {
    for (let risposta of survey.value.risposte) {
      await ApiService.put(`/Survey/Risposta/${risposta.id}`, risposta);
    }
  } catch (error) {
    console.log(error);
  } finally {
    loading.value.gen = false;
  }
}
</script>

<template>
  <master-layout
    :showContentHeader="false"
    :showHeader="true"
    smallTitle="Survey"
    :bigTitle="survey.t_nome['it-IT']"
  >
    <!-- Conditionally render responses if survey is completed or deleted -->
    <div v-if="isSurveyCompleteOrDeleted()" class="w-full">
      <ion-card
        v-for="(risposta, index) in survey.risposte"
        :key="index"
        class="shadow-4 animate__animated animate__bounceInRight"
      >
        <ion-card-header>
          <h2 style="color: white">
            {{ risposta.domanda.t_domanda["it-IT"] }}
          </h2>
        </ion-card-header>
        <ion-card-content>
          <div
            class="w-full d-flex flex-column justify-content-center align-items-center"
          >
            <!-- Display stars as readonly -->
            <div
              class="w-full d-flex flex-column justify-content-between align-items-center"
            >
              <div
                class="w-full flex d-flex justify-content-between align-items-center"
              >
                <span>{{ risposta.domanda.t_labelDa["it-IT"] }}</span>
                <span>{{ risposta.domanda.t_labelA["it-IT"] }}</span>
              </div>
              <div
                class="w-full d-flex justify-content-between align-items-center"
              >
                <ion-icon
                  v-for="rating in risposta.domanda.rateA -
                  risposta.domanda.rateDa +
                  1"
                  :key="rating"
                  :icon="rating <= risposta.valore ? star : starOutline"
                  style="font-size: 2rem; color: yellow"
                ></ion-icon>
              </div>
            </div>
          </div>
        </ion-card-content>
      </ion-card>

      <div class="w-full flex justify-content-center align-items-center mt-4">
        <button
          @click="router.push({ name: 'Surveys' })"
          class="button-primary p-sm mr-2"
        >
          Torna indietro
        </button>
      </div>
    </div>

    <!-- Final card to confirm answers -->
    <div v-if="showFinalCard && !isSurveyCompleteOrDeleted()" class="w-full">
      <ion-card class="shadow-4 animate__animated animate__bounceInRight">
        <ion-card-header>
          <h2 style="color: white">Review Your Answers</h2>
        </ion-card-header>
        <ion-card-content>
          <div
            class="w-full d-flex flex-column justify-content-center align-items-center"
          >
            <p>Do you want to submit your answers?</p>
            <div class="d-flex justify-content-around">
              <ion-button @click="confirmCompletion(true)">Yes</ion-button>
              <ion-button @click="confirmCompletion(false)">No</ion-button>
            </div>
          </div>
        </ion-card-content>
      </ion-card>
    </div>

    <!-- Current question card -->
    <div
      v-if="currentRisposta && !showFinalCard && !isSurveyCompleteOrDeleted()"
      class="w-full"
    >
      <ion-card class="shadow-4 animate__animated animate__bounceInRight">
        <ion-card-header>
          <h2 style="color: white">
            {{ currentRisposta.domanda.t_domanda["it-IT"] }}
          </h2>
        </ion-card-header>
        <ion-card-content>
          <div
            class="w-full d-flex flex-column justify-content-center align-items-center"
          >
            <!-- Rating with labels -->
            <div
              class="w-full d-flex flex-column justify-content-between align-items-center"
            >
              <div
                class="w-full flex d-flex justify-content-between align-items-center"
              >
                <span>{{ currentRisposta.domanda.t_labelDa["it-IT"] }}</span>
                <span>{{ currentRisposta.domanda.t_labelA["it-IT"] }}</span>
              </div>
              <div
                class="w-full d-flex justify-content-between align-items-center"
              >
                <ion-icon
                  v-for="rating in currentRisposta.domanda.rateA -
                  currentRisposta.domanda.rateDa +
                  1"
                  :key="rating"
                  :icon="rating <= currentRisposta.valore ? star : starOutline"
                  @click="handleRatingClick(rating)"
                  style="font-size: 2rem; cursor: pointer; color: yellow"
                ></ion-icon>
              </div>
            </div>
          </div>
        </ion-card-content>
      </ion-card>
    </div>
  </master-layout>
</template>
